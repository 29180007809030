.image-container {
    position: absolute;
    width: 50%; 
    top: 50%;
    transform: translateY(-50%);
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

.image-container.left {
    left: 0;
    animation-name: slideFromLeft;
}

.image-container.right {
    right: 0;
    animation-name: slideFromRightAndBack;
}

@keyframes slideFromLeft {
    0%, 100% {
        transform: translateY(-50%) translateX(-100%);
    }
    25%, 75% {
        transform: translateY(-50%) translateX(10%);
    }
}

@keyframes slideFromRightAndBack {
    0% {
        transform: translateY(-50%) translateX(150%);
    }
    25% {
        transform: translateY(-50%) translateX(-10%);
    }
    50%, 75% {
        transform: translateY(-50%) translateX(-10%);
    }
    100% {
        transform: translateY(-50%) translateX(150%);
    }
}
